import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
     
  {/* <!-- info section --> */}
  <section class="info_section ">
    <div class="container">
      <h4>
        Get In Touch
      </h4>
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="info_items">
            <div class="row">
              <div class="col-md-4">
                <Link href="">
                  <div class="item ">
                    <div class="img-box ">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text
                    </p>
                  </div>
                </Link>
              </div>
              <div class="col-md-4">
                <Link href="">
                  <div class="item ">
                    <div class="img-box ">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <p>
                      +02 1234567890
                    </p>
                  </div>
                </Link>
              </div>
              <div class="col-md-4">
                <Link href="">
                  <div class="item ">
                    <div class="img-box">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                    <p>
                      demo@gmail.com
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="social-box">
      <h4>
        Follow Us
      </h4>
      <div class="box">
        <Link href="">
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </Link>
        <Link href="">
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </Link>
        <Link href="">
          <i class="fa fa-youtube" aria-hidden="true"></i>
        </Link>
        <Link href="">
          <i class="fa fa-instagram" aria-hidden="true"></i>
        </Link>
      </div>
    </div>
  </section>


{/* 
  <!-- end info_section -->

  <!-- footer section --> */}
  <footer class="footer_section">
    <div class="container">
      <p>
        &copy; <span id="displayDateYear"></span> All Rights Reserved By
        <Link href="https://html.design/">Free Html Templates</Link>
      </p>
    </div>
  </footer>
  {/* <!-- footer section --> */}

    </div>
  )
}

export default Footer
