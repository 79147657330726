import React from 'react'
import { Link } from 'react-router-dom'

function About() {
  return (
    <div>
<div class="hero_area">
    {/* <!-- header section strats --> */}
    
    {/* <!-- end header section --> */}
  </div>

  {/* <!-- about section --> */}

  <section class="about_section layout_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6">
          <div class="detail-box">
            <h2>
              About us
            </h2>
            <p>
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomisedThere are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised
            </p>
            <Link to="">
              Read More
            </Link>
          </div>
        </div>
        <div class="col-lg-7 col-md-6">
          <div class="img-box">
            <img src="images/about-img.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </section>

    </div>
  )
}

export default About
