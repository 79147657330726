import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Layout from './Layout';
import Home from './Home';
import About from './About';
import Service from './Service';
import Contact from './Contact';



// const router = createBrowserRouter([
//   {
//     path:'/',
//     element: <Layout/>,
//     children : [
//       {
//         path: '',
//         element: <Home/>
//       },
//       {
//         path:'about',
//         element: <About/>
//       }
//     ]
//   }
// ]) 

const router = createBrowserRouter(

createRoutesFromElements(
  <Route path='/' element={<Layout/>}>
    <Route path='' element={<Home/>}/>
    <Route path='about' element={<About/>}/>
   <Route path='service' element={<Service/>}/>
   <Route path='contact' element={<Contact/>}/>
    
  </Route>
)

)
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
