import React from 'react'

function Contact() {
  return (
    <>
      
  <section className="contact_section layout_padding">
    <div className="container">
      <div className="heading_container">
        <h2>
          Contact Us
        </h2>
      </div>
      <div className="row">
        <div className="col-md-6">
          <form action="">
            <div>
              <input type="text" placeholder="Name" />
            </div>
            <div>
              <input type="text" placeholder="Phone Number" />
            </div>
            <div>
              <input type="email" placeholder="Email" />
            </div>
            <div>
              <input type="text" className="message-box" placeholder="Message" />
            </div>
            <div className="d-flex ">
              <button>
                SEND
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <div className="map_container">
            <div className="map">
              <div id="googleMap" style={{width:'100%',height:'100%'}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d447925.3392658828!2d77.290734!3d28.70709!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfc9c133fe2f9%3A0xcf68490a6216d102!2sKrishna%20Nagar%2C%20Delhi%2C%20110051!5e0!3m2!1sen!2sin!4v1727726653730!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Contact
